@use "../../settings" as variable;
@use "../../tools/mixin" as mixin;


/* Footer
------------------------------------------------------------- */
.footer{
// calcを計算式のままコンパイルするための変数
$calcSp: 750;
$calcPc: 1200;
$calcXxl: 1440;
$calcPcMax: 1920;

  display: block;
  position: relative;
  z-index: 2000;
  padding-top: calc(56 * (100vw / #{$calcSp}));
  background-color: var(--color-white);
    @include mixin.responsive("md") {
      padding-top: calc(60 * (100vw / #{$calcPc}));
    }
    @include mixin.responsive("xl") {
      padding-top: 60px;
    }
  &__share{
    display: block;
    &--ttl{
      margin-bottom: calc(30 * (100vw / #{$calcSp}));
      font-size: calc(24 * (100vw  / #{$calcSp})); 
      font-family: var(--font-en);
      text-align: center;
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: 0.15em;
      text-indent: 0.15em;
      @include mixin.responsive("md") {
        margin-bottom: calc(20 * (100vw / #{$calcPc}));
        font-size: calc(14 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }
    &--list{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto calc(50 * (100vw / #{$calcSp}));
      width: calc(210 * (100vw / #{$calcSp}));
      @include mixin.responsive("md") {
        margin-bottom: calc(60 * (100vw / #{$calcPc}));
        width: calc(114 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        margin-bottom: 60px;
        width: 114px;
      }
    }  
    &--icon{
      display: block;
      width: calc(46 * (100vw / #{$calcSp}));
      @include mixin.responsive("md") {
        width: calc(26 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        width: 26px;
      }
      > a{
        display: block;
        @include mixin.responsive("md") {
          transition: all .6s ease;
        }
        &:hover {
          @include mixin.responsive("md") {
            opacity: .6;
          }
        }
      }
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  &__copy{
    display: block;
    padding: calc(16 * (100vw / #{$calcSp})) 0;
    background-color: #000;
    color: var(--color-white);
    font-family: var(--font-en);
    font-size: calc(22 * (100vw / #{$calcSp}));
    text-align: center;
    line-height: 1;
    letter-spacing: 0.15em;
    @include mixin.responsive("md") {
    padding: calc(14 * (100vw / #{$calcPc})) 0;
    font-size: calc(12 * (100vw / #{$calcPc}));
    }
    @include mixin.responsive("xl") {
      padding: 14px 0;
      font-size: 12px;
      }
  }

  
  @include mixin.responsive("md") {
  }
  
}


/* .online-store__nav--outer (オンラインストアへのナビゲーション)
------------------------------------------------------------- */
.online-store__nav {
  // calcを計算式のままコンパイルするための変数
  $calcSp: 750;
  $calcPc: 1200;
  $calcPcMax: 1920;
  &--outer {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background-color: var(--color-white);
    box-shadow: 0px -1px 9px rgba(0, 0, 0, 0.12);
    @include mixin.responsive("md") {
      bottom: 0;
      right: 0;
      width: calc(210 * (100vw / #{$calcPc}));
      height: calc(182 * (100vw / #{$calcPc}));
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.18);
    }
    @include mixin.responsive("xl") {
      width: 210px;
      height: 182px;
    }
  }
  @include mixin.responsive("md-max") {
    display: flex;
    justify-content: center;
  }
  &--item {
    position: relative;
    width: calc(354 * (100vw / #{$calcSp}));
    @include mixin.responsive("md") {
      width: 100%;
      transition: var(--transition-default);
    }
    &:nth-child(2) {
      @include mixin.responsive("md-max") {
        width: calc(396 * (100vw / #{$calcSp}));
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc(58 * (100vw / #{$calcSp}));
      margin: auto;
      width: calc(12 * (100vw / #{$calcSp}));
      height: calc(12 * (100vw / #{$calcSp}));
      background-image: url(../../assets/img/common/tab_icon.svg);
      background-size: contain;
      background-repeat: no-repeat;
      @include mixin.responsive("md") {
        right: calc(28 * (100vw / #{$calcPc}));
        width: calc(6 * (100vw / #{$calcPc}));
        height: calc(6 * (100vw / #{$calcPc}));
        transition: var(--transition-default);
      }
      @include mixin.responsive("xl") {
        right: 28px;
        width: 6px;
        height: 6px;
      }
    }
    &:hover {
      &::after {
        @include mixin.responsive("md") {
          background-image: url(../../assets/img/common/tab_icon_2.svg);
        }
      }
    }
    &:not(:last-child) {
      .online-store__nav--link {
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 1px;
          height: calc(60 * (100vw / #{$calcSp}));
          background-color: var(--color-gray-line);
          @include mixin.responsive("md") {
          top: auto;
          bottom: -1px;
          right: 0;
          left: 0;
            width: calc(174 * (100vw / #{$calcPc}));
            height: 1px;
            transition: var(--transition-default);
          }
          @include mixin.responsive("xl") {
            width: 174px;
          }
        }
        &:hover {
          &::before {
            @include mixin.responsive("md") {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
  &--link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(9 * (100vw / #{$calcSp})) 0;
    @include mixin.responsive("md") {
      padding: calc(12 * (100vw / #{$calcPc})) 0 calc(11 * (100vw / #{$calcPc}));
      transition: var(--transition-default);
    }
    @include mixin.responsive("xl") {
      padding: 12px 0 11px;
    }
    &:hover {
      @include mixin.responsive("md") {
        background-color: var(--color-main);
      }
      .online-store__nav--logo {
        @include mixin.responsive("md") {
          opacity: 0;
        }
        &--hover {
          @include mixin.responsive("md") {
            opacity: 1;
          }
        }
      }
    }
  }
  &--logo {
    margin-right: calc(24 * (100vw / #{$calcSp}));
    width: calc(200 * (100vw / #{$calcSp}));
    @include mixin.responsive("md") {
      margin-right: calc(20 * (100vw / #{$calcPc}));
      width: calc(150 * (100vw / #{$calcPc}));
    }
    @include mixin.responsive("xl") {
      margin-right: 20px;
      width: 150px;
      transition: var(--transition-default);
    }
    &--hover {
      display: none;
      transition: var(--transition-default);
      @include mixin.responsive("md") {
        display: block;
        position: absolute;
        opacity: 0;
        margin-right: calc(20 * (100vw / #{$calcPc}));
        width: calc(150 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        margin-right: 20px;
        width: 150px;
        transition: var(--transition-default);
      }
      
    }
  }
  &--item {
    &:nth-child(2) {
      .online-store__nav--logo {
        @include mixin.responsive("md-max") {
          width: calc(224 * (100vw / #{$calcSp}));
        }
      }
    }
  }
}