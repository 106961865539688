@use "../../settings" as variable;
@use "../../tools/mixin" as mixin;


/* Header
------------------------------------------------------------- */

.header {
  // calcを計算式のままコンパイルするための変数
  $calcSp: 750;
  $calcPc: 1200;
  $calcXxl: 1440;
  $calcPcMax: 1920;
  
  width: 100%;
  z-index: 1000;
  background-color: var(--color-bg);
  transition: var(--transition-default);
  @include mixin.responsive("md-max") {
    position: fixed;
  }
  &.hidden {
    @include mixin.responsive("md") {
      position: relative;
      top: calc(-155 * (100vw / #{$calcPc}));
      transition: var(--transition-default);
    }
    @include mixin.responsive("xl") {
      top: -155px;
    }
    &.fixed {
      @include mixin.responsive("md") {
        position: sticky;
        top: 0;
        margin-bottom: 0;
        transition: var(--transition-default);
      }
      @include mixin.responsive("xl") {
        top: 0;
        margin-bottom: 0;
      }
    }
  }
  @media (orientation: landscape) {
    position: static;
  }
  &--news {
    position: fixed;
    @media (orientation: landscape) {
      position: static;
    }
    @include mixin.responsive("md") {
      position: fixed;
    }
  }
  &--bg {
    @include mixin.responsive("md") {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: calc(155 * (100vw / #{$calcPc}));
    }
    @include mixin.responsive("xl") {
      height: 155px;
    }
  }
  &-logo {
    height:  calc(140 * (100vw / #{$calcSp}));
    @include mixin.responsive("md") {
      height: calc(105 * (100vw / #{$calcPc}));
    }
    @include mixin.responsive("xl") {
      height: 105px;
    }
    &__link {
      width: calc(234 * (100vw / #{$calcSp}));
      display: block;
      margin: 0 auto;
      padding: calc(26 * (100vw / #{$calcSp})) 0;
      @include mixin.responsive("md") {
        width: calc(160 * (100vw / #{$calcPc}));
        padding: calc(23 * (100vw / #{$calcPc})) 0;
      }
      @include mixin.responsive("xl") {
        width: 160px;
        padding: 23px 0;
      }
    }
    &__img {
      width: 100%;
    }
  }
  &-nav {
    background-color: var(--color-white);
    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: calc(60 * (100vw / #{$calcSp}));
      height:  calc(70 * (100vw / #{$calcSp}));
      @include mixin.responsive("md") {
        gap: calc(80 * (100vw / #{$calcPc}));
        height: calc(49 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        gap: 80px;
        height: 49px;
      }
      &--item {
        font-size: calc(24 * (100vw / #{$calcSp}));
        letter-spacing: 0.15em;
        @include mixin.responsive("md") {
          font-size: calc(14 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          font-size: 14px;
        }
        a {
          position: relative;
          display: block;
          height:  calc(70 * (100vw / #{$calcSp}));
          padding: calc(21 * (100vw / #{$calcSp})) 0 calc(21 * (100vw / #{$calcSp}));
          line-height: 1;
          @include mixin.responsive("md") {
            height: calc(49 * (100vw / #{$calcPc}));
            padding: calc(17 * (100vw / #{$calcPc})) 0 calc(18 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            height: 49px;
            padding: 17px 0 18px
          }
          &::before {
            @include mixin.responsive("md") {
              position: absolute;
              content: "";
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: var(--color-black-dark);
              transition: var(--transition-default);
              transform: scaleX(0);
            }
          }
          &:hover {
            &::before {
              @include mixin.responsive("md") {
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }
  }
}