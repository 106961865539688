// font
$base_font_family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", MS PGothic,  sans-serif;
$base_font_size: 1.4rem;
$base_line_height: 1.6;
$base_letter_spacing: 0.02em;

// z-indexの管理
$z_index_nav_drawer: 1002;
$z_index_nav_header: 1000;
$z_index_photo_modal: 1002;

// デザインアートボードサイズの管理
$design_pc: 1340;
$design_sp: 375;
