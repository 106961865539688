@use "../../settings" as variable;
@use "../../tools/mixin" as mixin;


/* Header
------------------------------------------------------------- */

.breadcrumb {
  // calcを計算式のままコンパイルするための変数
  $calcSp: 750;
  $calcPc: 1200;
  $calcPcMax: 1920;

  padding: calc(250 * (100vw / #{$calcSp})) 0  calc(70 * (100vw / #{$calcSp}));
  margin: 0 auto;
  width: calc(630 * (100vw / #{$calcSp}));
  @include mixin.responsive("md") {
    padding: calc(184 * (100vw / #{$calcPc})) 0 calc(40 * (100vw / #{$calcPc}));
    width: calc(900 * (100vw / #{$calcPc}));
  }
  @include mixin.responsive("xl") {
    padding: 184px 0 40px;
    width: 900px;
  }
  &__list {
    display: flex;
    gap: calc(20 * (100vw / #{$calcSp}));
    @include mixin.responsive("md") {
      gap: calc(6 * (100vw / #{$calcPc}));
    }
    @include mixin.responsive("xl") {
      gap: 6px;
    }
  }
  &__item {
    font-size: calc(20 * (100vw / #{$calcSp}));
    letter-spacing: 0.05em;
    text-transform: uppercase;
    @include mixin.responsive("md") {
      font-size: calc(12 * (100vw / #{$calcPc}));
    }
    @include mixin.responsive("xl") {
      font-size: 12px;
    }
    &:not(:last-child) {
      &::after {
        content: ">";
        display: inline-block;
        @include mixin.responsive("md") {
          padding-left: calc(4 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          padding-left: 4px;
        }
      }
    }
    &--link {
      text-decoration-line: underline;
      transition: var(--transition-default);
      &:hover {
        opacity: var(--hover-opacity);
        text-decoration-line: underline;
      }
    }
  }
}