@use "../settings" as variable;
@use "../tools/mixin" as mixin;

*,
*::before,
*::after {
    box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
ol {
    padding: 0;
    margin: 0;
}
ul[role="list"],
ol[role="list"],
li {
    list-style: none;
}
html {
    scroll-behavior:auto;
}
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
}
a:not([class]) {
    text-decoration-skip-ink: auto;
}
a,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    outline: none;
    color: inherit;
}
img,
picture {
    width: auto;
    height: auto;
    max-width: 100%;
    display: block;
}
input,
button,
textarea,
select {
    font: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
