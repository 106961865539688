@use "../settings" as variable;
@use "../tools/mixin" as mixin;

// calcを計算式のままコンパイルするための変数
$calcSp: 750;
$calcPc: 1200;
$calcPcMax: 1920;

:root {

  /* color site */
  --color-main: #000;
  --color-white: #fff;
  --color-light: #e3e3e3;
  --color-dark: #707070;
  --color-bg: #EFEFEF;
  --color-bg-light: #FAFAFA;
  --color-bg-light-middle: #F7F7F7;
  --color-bg-light-little: #F3F3F3;
  --color-bg-blue: #C4CBD5;
  --color-bg-lpfg: #ADB0B7;
  --color-bg-modal: rgba(0, 0, 0, 0.7);
  --color-bg-btn: rgba(0, 0, 0, 0.12);
  --color-alpha: rgba(255, 255, 255, 0.7);
  --color-red: #BA0C2F;
  --color-history-line: #515151;
  
  /* color black */
  --color-black: #111;
  --color-black-dark: #222;

  /* color gray */
  --color-gray: #ddd;
  --color-gray-dark: #444;
  --color-gray-middle: #666;
  --color-gray-light: #ddd;
  --color-gray-light-little: #EAEAEA;
  --color-gray-light-middle: #ACACAC;
  --color-gray-line: #D8D8D8;

  /* font */
  --font-ja: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", MS PGothic,  sans-serif;
  --font-en: europa, sans-serif;
  /* Container max width */
  @include mixin.responsive("md") {
    --container-default: 118rem;
    --container-small: 100rem;
    --container-xs: 80rem;
  }
  /* Effect related */
  --hover-opacity: 0.7;
  --transition-default: .2s cubic-bezier(0.45, 0, 0.55, 1);
}
html {
  font-size: 62.5%;
}
body {
  margin: 0;
  font-family: var(--font-ja);
  font-size: variable.$base_font_size;
  line-height: variable.$base_line_height;
  letter-spacing: variable.$base_letter_spacing;
  word-break: break-all;
  background-color: var(--color-white);
  &.is-locked {
    position: fixed;
    width: 100%;
  }
}
h6, h5, h4, h3, h2, h1 {
  font-size: variable.$base_font_size;
  font-weight: normal;
  line-height: variable.$base_line_height;
}
img {
  image-rendering: -webkit-optimize-contrast;
}
address {
  font-style: normal;
}
/* lazysizes */
.lazyload {
  opacity: 0;
  transition: opacity .8s ease-out;
}
.lazyloaded {
  opacity: 1;
}
/**
 * vue
 */
[v-cloak] {
  opacity: 0;
}
/**
 * window resize animate
 */
main, .container {
  transition: all .3s;
}

.only-sp{
  @include mixin.responsive("md-max") {
    display: block !important;
  }
  @include mixin.responsive("md") {
    display: none !important;
  }
}
.only-pc{
  @include mixin.responsive("md-max") {
    display: none !important;
  }
  @include mixin.responsive("md") {
    display: block !important;
  }
}